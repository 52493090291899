import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: 'history',

  routes: [
    {
      path: '/login/',
      component: require('@/auth/pages/auth/Login.vue').default,
      name: 'auth.login'
    },
    {
      path: '/password/reset/',
      component: require('@/auth/pages/auth/Email.vue').default,
      name: 'auth.reset'
    },
    {
      path: '/password/reset/:token',
      component: require('@/auth/pages/auth/Password.vue').default,
      name: 'auth.password'
    },
    {
      path: '/users/*',
      redirect: '/users'
    }
  ]
})
