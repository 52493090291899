<template>
  <div class="h-100">
    <div class="auth h-100">
      <div class="auth--header">
        <img
          src="/img/logo_grey.png"
          alt="">
      </div>

      <div class="auth--content">
        <transition name="fade">
          <router-view/>
        </transition>
      </div>

      <div class="auth--footer">

      </div>
    </div>
  </div>
</template>

<script>


  export default {
    name: 'Auth'
  }
</script>
