import Vue from 'vue'
import VeeValidate, { Validator } from 'vee-validate'
import pl from 'vee-validate/dist/locale/pl'

// ElementUI
import ElementUI from 'element-ui/lib/index'
import locale from 'element-ui/lib/locale/lang/pl'

// vee-validate
import customRules from '@/shared/utils/validation'

import ValidationErrors from '@/shared/components/ValidationErrors'
import CustomFormItem from '@/shared/components/CustomFormItem'

Vue.use(ElementUI, { locale })

// Axios
window.axios = require('axios')
// eslint-disable-next-line no-undef
axios.defaults.headers.common = {
  'X-Requested-With': 'XMLHttpRequest',
  'X-CSRF-TOKEN': window.csrfToken,
  'Accept': 'application/json'
}
Vue.prototype.$http = window.axios

const vvconfig = {
  errorBagName: 'formErrors',
  fieldsBagName: 'formFields',
  locale: 'pl'
}
Vue.use(VeeValidate, vvconfig)
Validator.localize('pl', pl)
customRules(Validator)
Vue.component('errors', ValidationErrors)
Vue.component('custom-form-item', CustomFormItem)
