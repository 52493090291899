<template>
  <div
    :class="[{
      'is-error': errors && errors.has(prop),
      'is-required': required
    }]"
    class="el-form-item">
    <label
      v-if="label || $slots.label"
      :for="labelFor"
      class="el-form-item__label">
      <slot name="label">{{ label }}</slot>
      <el-popover
        v-if="hasSlotHelp"
        placement="top-start"
        width="300"
        trigger="hover">
        <span
          slot="reference"
          class="el-form-item__help text-info"><i class="fas fa-info-circle"></i></span>
        <slot name="content">
          <slot name="help"></slot>
        </slot>
      </el-popover>
    </label>

    <div class="el-form-item__content">
      <slot/>

      <transition name="el-zoom-in-top">
        <div
          v-if="errors && errors.has(prop)"
          class="el-form-item__error">
          {{ errors.first(prop) }}
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'CustomFormItem',

    props: {
      label: {
        type: String,
        default: ''
      },
      prop: {
        type: String,
        default: ''
      },
      errors: {
        type: Object | Boolean,
        default: false
      },
      required: {
        type: Boolean,
        default: undefined
      },
      for: {
        type: String,
        default: ''
      }
    },

    computed: {
      labelFor () {
        return this.for || this.prop
      },

      hasSlotHelp () {
        return this.$slots.help
      }
    }
  }
</script>
