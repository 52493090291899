<template>
  <div>
    <h1>Odzyskaj hasło</h1>
    <p class="text-muted">Podaj adres e-mail przypisany do twojego konta</p>

    <el-form
      v-loading="loading"
      ref="emailForm"
      :model="form"
      label-position="top"
      @submit.native.prevent="handleForm('emailForm')">

      <form-alerts :messages="invalidMsg"/>

      <el-alert
        v-if="successMsg != ''"
        :title="successMsg"
        type="success"
        class="mb-5"
        show-icon/>

      <el-form-item label="E-mail">
        <el-input
          v-validate="'required|email'"
          v-model="form.email"
          type="text"
          name="email"/>
        <errors
          :errors="formErrors"
          field="email"/>
      </el-form-item>

      <el-form-item>
        <el-button
          class="w-100"
          native-type="submit"
          type="primary">Wyślij link</el-button>
      </el-form-item>
    </el-form>

    <hr>

    <router-link
      :to="{name: 'auth.login'}"
      style="width: 100%"
      class="el-button w-100">Powróć do logowania</router-link>
  </div>
</template>

<script>
  import forms from '@/shared/mixins/forms'
  import {postRequest} from '@/shared/utils/http'

  export default {

    mixins: [
      forms
    ],

    data: () => {
      return {
        form: {
          email: ''
        },
        successMsg: ''
      }
    },

    methods: {
      submitForm: function () {
        return new Promise((resolve, reject) => {
          postRequest('/password/email', this.form)
            .then(response => {
              this.successMsg = response.msg

              resolve(response)
            })
            .catch(error => {
              reject(error)
            })
        })
      }
    }
  }
</script>
