<template>
  <div>
    <h1>Ustaw nowe hasło</h1>
    <el-form
      v-loading="loading"
      ref="loginForm"
      :model="form"
      label-position="top"
      @submit.native.prevent="handleForm('loginForm')"
    >

      <form-alerts :messages="invalidMsg"/>

      <input
        :value="form.token"
        type="hidden"
        name="token">

      <el-form-item label="E-mail">
        <el-input
          v-validate="'required|email'"
          v-model="form.email"
          type="text"
          name="email"/>
        <errors
          :errors="formErrors"
          field="email"/>
      </el-form-item>

      <el-form-item label="Hasło">
        <PasswordField
          v-validate="'required|password_strength'"
          v-model="form.password"
          :with-suggestions="true"
          name="password"/>
        <errors
          :errors="formErrors"
          field="password"/>
      </el-form-item>

      <el-form-item>
        <el-button
          class="w-100"
          native-type="submit"
          type="primary">Ustaw hasło
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
  import forms from '@/shared/mixins/forms'
  import {postRequest} from '@/shared/utils/http'
  import PasswordField from '@/shared/components/PasswordField'

  export default {

    components: {
      PasswordField
    },

    mixins: [
      forms
    ],

    data: () => {
      return {
        form: {
          email: window.email,
          password: '',
          token: window.token,
          password_confirmation: 'a'
        },
        passwordType: 'password'
      }
    },

    methods: {
      submitForm: function () {
        this.form.password_confirmation = this.form.password
        return new Promise((resolve, reject) => {
          postRequest('/password/reset', this.form)
            .then(response => {
              this.$router.push({path: '/admin/dashboard'})
              this.successMsg = response.msg
              if (response.intended) {
                window.location = response.intended
              }
              resolve(response)
            })
            .catch(error => {
              reject(error)
            })
        })
      }
    }
  }
</script>
