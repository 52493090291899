import FormAlerts from '@/shared/components/FormAlerts'
import { checkRequired } from '@/shared/utils/validation'
import forIn from 'lodash-es/forIn'

export default {
  data () {
    return {
      invalidMsg: [],
      loading: false,
      errorTabs: [],
      uploadHeaders: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-TOKEN': window.csrfToken,
        'Accept': 'application/json'
      }
    }
  },

  components: {
    FormAlerts
  },

  methods: {
    checkRequired: (rules) => {
      return checkRequired(rules)
    },

    handleForm: function (formName, $event) {
      this.loading = true
      this.invalidMsg = []
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          this.submitForm()
            .then(_ => {
              this.loading = false
              if (typeof this.$validator !== 'undefined') {
                this.$validator.reset()
              }
            })
            .catch(error => {
              this.loading = false
              if (typeof this.$validator !== 'undefined') {
                this.$validator.reset()
              }

              if (error.status === 422) { // validation error
                forIn(error.data.errors, (err, field) => {
                  this.invalidMsg.push(err[0])
                })
              }
            })

          return true
        } else {
          this.$nextTick(() => {
            this.checkInvalidTabs()
          })
        }
        this.loading = false
        return false
      })
    },

    clearInvalidMsg: function () {
      this.invalidMsg = []
    },

    checkInvalidTabs: function () {
      if (document.querySelector('form .el-tabs__nav') !== null) {
        const tabs = document.querySelectorAll('form .el-tab-pane')
        tabs.forEach((tab) => {
          this.checkTabHasErrors(tab)
        })
      }
    },

    checkTabHasErrors: function (tab) {
      const tabName = tab.getAttribute('id').replace('pane-', '')
      if (tab.querySelector('.el-form-item__error') !== null) {
        if (!this.errorTabs.includes(tabName)) {
          this.errorTabs.push(tabName)
        }
        return true
      } else {
        const ind = this.errorTabs.indexOf(tabName)
        if (ind !== -1) {
          this.errorTabs.splice(ind, 1)
        }
        return false
      }
    }
  }
}
