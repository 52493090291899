/* eslint-disable camelcase */
import {getRequest} from '@/shared/utils/http'
import moment from 'moment'
import toNumber from 'lodash-es/toNumber'
import Dinero from 'dinero.js'

export const checkRequired = (rules) => {
  if (typeof rules === 'undefined') {
    return false
  }
  return rules.hasOwnProperty('required')
}

const isUnique = (url, value, id = 0) => {
  return getRequest(url, { id: id, keyword: value })
    .then(response => {
      return {
        valid: response.valid,
        data: {
          message: response.data.message
        }
      }
    })
}

const ajaxValidation = (url, value) => {
  return getRequest(url, { keyword: value })
    .then(response => {
      if (response.valid) {
        if (url === '/api/validation/iban') {
          vm.$emit('iban_validated')
        }
        if (url === '/api/validation/iban_tax') {
          vm.$emit('iban_tax_validated')
        }
      }
      return {
        valid: response.valid,
        data: {
          message: response.data.message
        }
      }
    })
}

const rules = {
  password_strength: {
    validate: value => {
      let result = zxcvbn(value)
      return result.score >= 1
    },
    getMessage: field => 'Proszę podaj silne hasło'
  },

  user_unique: {
    validate: (value, [id]) => isUnique('/api/users/unique', value, id),
    getMessage: (field, params, data) => {
      return data.message
    }
  },

  front_user_unique: {
    validate: (value, [id]) => isUnique('/api/front/users/unique', value, id),
    getMessage: (field, params, data) => {
      return data.message
    }
  },

  number_unique: {
    validate: (value, [id]) => isUnique('/api/agency/unique_number', value, id),
    getMessage: (field, params, data) => {
      return data.message
    }
  },

  number_local_unique: {
    validate: (value, [id]) => isUnique('/api/agency/unique_number_local', value, id),
    getMessage: (field, params, data) => {
      return data.message
    }
  },

  name_unique: {
    validate: (value, [id]) => isUnique('/api/agency/unique_name', value, id),
    getMessage: (field, params, data) => {
      return data.message
    }
  },

  role_name_unique: {
    validate: (value, [id]) => isUnique('/api/roles/unique_name', value, id),
    getMessage: (field, params, data) => {
      return data.message
    }
  },

  role_display_name_unique: {
    validate: (value, [id]) => isUnique('/api/roles/unique_display_name', value, id),
    getMessage: (field, params, data) => {
      return data.message
    }
  },

  peselNumber: {
    validate: (value) => {
      let weight = [1, 3, 7, 9, 1, 3, 7, 9, 1, 3]
      let sum = 0
      let controlNumber = parseInt(value.substring(10, 11))

      for (let i = 0; i < weight.length; i++) {
        sum += (parseInt(value.substring(i, i + 1)) * weight[i])
      }
      sum = sum % 10
      return (10 - sum) % 10 === controlNumber
    },
    getMessage: (field, params, data) => {
      return `Błędy format numeru PESEL`
    }
  },

  nip: {
    validate: (value) => {
      // if (value.length === 10 && value[9] === '_') {
      //   return {
      //     valid: false,
      //     data: {
      //       message: 'Podaj prawidłowy numer NIP'
      //     }
      //   }
      // }
      return ajaxValidation('/api/validation/nip', value)
    },
    getMessage: (field, params, data) => {
      return data.message
    }
  },
  credit: {
    validate: (value, [brutto, financial]) => {
      if (parseFloat(brutto) < parseFloat(value)) {
        return {
          valid: false,
          data: {
            message: 'Kwota nie może być większa od ceny brutto'
          }
        }
      }
      if (financial !== 2) {
        if (parseFloat(brutto) > parseFloat(value)) {
          return {
            valid: false,
            data: {
              message: 'Zbyt mała/duża kwota. Musi być równa kwocie brutto.'
            }
          }
        }
      }

      return {
        valid: true
      }
      // return {
      //   valid: false,
      //   data: {
      //     message: 'Kwota niższa niż 30% ceny brutto umowy'
      //   }
      // }
    },
    hasTarget: true,
    getMessage: (field, params, data) => {
      return data.message
    }
  },
  credit_1: {
    validate: (value, [netto, brutto, credit_2, financial]) => {
      if (parseFloat(brutto) < parseFloat(value)) {
        return {
          valid: false,
          data: {
            message: 'Kwota nie może być większa od ceny brutto'
          }
        }
      }
      if (financial != 2) {
        if (parseFloat(brutto) > parseFloat(value)) {
          if (credit_2 == 0) {
            return {
              valid: false,
              data: {
                message: 'Za mała/duża kwota. Musi być równa kwocie brutto.'
              }
            }
          }
          if (credit_2 != 0) {
            if ((value + credit_2) != brutto) {
              return {
                valid: false,
                data: {
                  message: 'Suma kredytów musi wynosić cenę brutto.'
                }
              }
            }
          }
        }
      }

      return {
        valid: true
      }
      // return {
      //   valid: false,
      //   data: {
      //     message: 'Kwota niższa niż 30% ceny brutto umowy'
      //   }
      // }
    },
    hasTarget: true,
    getMessage: (field, params, data) => {
      return data.message
    }
  },
  credit_2: {
    validate: (value, [netto, brutto, credit_1, financial]) => {
      if (credit_1 == 0) {
        return {
          valid: false,
          data: {
            message: 'Brak wpisanej kwoty dla pierwszego kredytodawcy'
          }
        }
      }
      if (parseFloat(brutto) < parseFloat(value)) {
        return {
          valid: false,
          data: {
            message: 'Kwota nie może być większa od ceny brutto'
          }
        }
      }
      if (financial != 2) {
        if (parseFloat(brutto) > parseFloat(value)) {
          if (credit_1 != 0) {
            if (value == 0) {
              return {
                valid: true
              }
            }
            if ((value + credit_1) != brutto) {
              return {
                valid: false,
                data: {
                  message: 'Suma kredytów musi wynosić cenę brutto.'
                }
              }
            }
          }
        }
      }
      return {
        valid: true
      }
    },
    hasTarget: true,
    getMessage: (field, params, data) => {
      return data.message
    }
  },

  price: {
    validate: (value) => {
      let price = Dinero({ amount: Math.round(value * 100), precision: 2 })

      if (price.isZero()) {
        return {
          valid: false,
          data: {
            message: 'Wartość umowy nie może być równa 0 PLN'
          }
        }
      }
      return {
        valid: true
      }
    },
    hasTarget: true,
    getMessage: (field, params, data) => {
      return data.message
    }
  },

  cash_1_validation: {
    validate: (value, [cash_2, cash_3, brutto, financial, credit]) => {
      let priceBrutto = Dinero({ amount: Math.round(brutto * 100), precision: 2 })
      let cash1 = Dinero({ amount: Math.round(value * 100), precision: 2 })
      let cash2 = Dinero({ amount: Math.round(cash_2 * 100), precision: 2 })
      let cash3 = Dinero({ amount: Math.round(cash_3 * 100), precision: 2 })

      if (financial === 2) {
        priceBrutto = priceBrutto.subtract(credit)
      }

      if (priceBrutto.lessThan(cash1)) {
        return {
          valid: false,
          data: {
            message: 'Transza nie może być większa od ceny brutto'
          }
        }
      }

      if (!cash1.add(cash2.add(cash3)).equalsTo(priceBrutto)) {
        return {
          valid: false,
          data: {
            message: 'Transze nie są spójne. Brakuje do pełnej sumy brutto'
          }
        }
      }

      if (cash1.lessThan(priceBrutto.multiply(0.3)) && financial !== 2) {
        return {
          valid: false,
          data: {
            message: 'Kwota niższa niż 30% ceny brutto umowy'
          }
        }
      }
      return {
        valid: true
      }
    },
    hasTarget: true,
    getMessage: (field, params, data) => {
      return data.message
    }
  },
  cash_2_validation: {
    validate: (value, [cash_1, cash_3, brutto, financial, credit]) => {
      let priceBrutto = Dinero({ amount: Math.round(brutto * 100), precision: 2 })
      let cash1 = Dinero({ amount: Math.round(cash_1 * 100), precision: 2 })
      let cash2 = Dinero({ amount: Math.round(value * 100), precision: 2 })
      let cash3 = Dinero({ amount: Math.round(cash_3 * 100), precision: 2 })

      if (financial === 2) {
        priceBrutto = priceBrutto.subtract(credit)
      }

      if (priceBrutto.lessThan(cash2)) {
        return {
          valid: false,
          data: {
            message: 'Transza nie może być większa od ceny brutto'
          }
        }
      }

      if (!cash2.add(cash1.add(cash3)).equalsTo(priceBrutto)) {
        return {
          valid: false,
          data: {
            message: 'Transze nie są spójne. Brakuje do pełnej sumy brutto'
          }
        }
      }
      if (cash1.equalsTo(priceBrutto)) {
        if (!cash2.isZero()) {
          return {
            valid: false,
            data: {
              message: 'Transza musi być równa 0 PLN przy jednorazowej wpłacie'
            }
          }
        }
      } else {
        if (cash2.lessThan(priceBrutto.multiply(0.5)) && financial !== 2) {
          return {
            valid: false,
            data: {
              message: 'Kwota niższa niż 50% ceny brutto umowy'
            }
          }
        }
      }
      return {
        valid: true
      }
    },
    hasTarget: true,
    getMessage: (field, params, data) => {
      return data.message
    }
  },
  cash_3_validation: {
    validate: (value, [cash_1, cash_2, brutto, financial, credit]) => {
      let priceBrutto = Dinero({ amount: Math.round(brutto * 100), precision: 2 })
      // let creditPrice = Dinero({ amount: parseInt((credit * 100)), precision: 2 })
      let cash1 = Dinero({ amount: Math.round(cash_1 * 100), precision: 2 })
      let cash2 = Dinero({ amount: Math.round(cash_2 * 100), precision: 2 })
      let cash3 = Dinero({ amount: Math.round(value * 100), precision: 2 })

      if (financial === 2) {
        priceBrutto = priceBrutto.subtract(credit)
      }

      if (priceBrutto.lessThan(cash3)) {
        return {
          valid: false,
          data: {
            message: 'Transza nie może być większa od ceny brutto'
          }
        }
      }

      if (!cash3.add(cash1.add(cash2)).equalsTo(priceBrutto)) {
        return {
          valid: false,
          data: {
            message: 'Transze nie są spójne. Brakuje do pełnej sumy brutto'
          }
        }
      }
      if (cash1.equalsTo(priceBrutto)) {
        if (!cash3.isZero()) {
          return {
            valid: false,
            data: {
              message: 'Transza musi być równa 0 PLN przy jednorazowej wpłacie'
            }
          }
        }
      } else {
        if (cash1.isZero() || cash2.isZero()) {
          return {
            valid: false,
            data: {
              message: 'Transza nie może być wypłacona bez poprzednich'
            }
          }
        }
      }
      return {
        valid: true
      }
    },
    hasTarget: true,
    getMessage: (field, params, data) => {
      return data.message
    }
  },

  iban: {
    validate: (value) => ajaxValidation('/api/validation/iban', value),
    getMessage: (field, params, data) => {
      return data.message
    }
  },

  iban_tax: {
    validate: (value) => ajaxValidation('/api/validation/iban_tax', value),
    getMessage: (field, params, data) => {
      return data.message
    }
  },

  min_money: {
    validate: value => {
      if (!value) {
        return false
      }
      return parseFloat(value) > 0
      // return parseFloat(value.replace(',', '.')) > 0
    },
    getMessage: (field, params, data) => {
      return `Pole ${field} powinno zawierać kwotę większą od 0`
    }
  }
}

export const address = {
  address: {
    required: true,
    max: 250
  },
  postcode: {
    required: true,
    max: 10
  },
  city: {
    required: true,
    max: 70
  },
  voivodeship: {
    required: true
  }
}

export default (Validator) => {
  Object.keys(rules).forEach(rule => {
    Validator.extend(rule, rules[rule])
  })

  const monthAfter = {
    validate: (value, [otherValue]) => {
      if (otherValue && value) {
        const startDate = moment(otherValue, moment.HTML5_FMT.MONTH)
        const endDate = moment(value, moment.HTML5_FMT.MONTH)
        return startDate <= endDate
      }

      return true
    },
    getMessage: (field, [target, inclusion]) => {
      return `Pole ${field} musi być po polu ${target}`
    }
  }

  const minMonth = {
    validate: (value, [args]) => {
      if (args && value) {
        const startDate = moment(args, moment.HTML5_FMT.MONTH)
        const endDate = moment(value, moment.HTML5_FMT.MONTH)
        return startDate <= endDate
      }

      return false
    },
    getMessage: (field, [target, inclusion]) => {
      return `Pole ${field} musi mieć wartość co najmniej ${target}`
    }
  }

  Validator.extend('month_after', monthAfter, { hasTarget: true })
  Validator.extend('min_month', minMonth)
}

export const UserDataRules = {
  pesel: {
    required: true,
    peselNumber: true
  }
}

export const PaymentRules = {
  price_brutto: {
    required: true
  },
  cash_1: {
    required: true,
    cash_1_validation: true
  }
}

export const generalRules = {
  required: {
    required: true
  },
  money_required: {
    required: true,
    decimal: [2, '.'],
    min_money: true
  },
  nip_required: {
    required: true,
    digits: 10,
    nip: true
  },
  nip: {
    digits: 10,
    nip: true
  },
  regon: {
    max: 15
  },
  krs: {
    max: 20
  },
  bup_knf_number: {
    max: 20
  },
  iban_required: {
    required: true,
    length: 26,
    iban: true
  },
  iban_tax_required: {
    required: true,
    length: 26,
    iban_tax: true
  },
  iban: {
    length: 26,
    iban: true
  },
  iban_tax: {
    length: 26,
    iban_tax: true
  }
}

// @deprecated rules below
export const ruleEmail = { type: 'email', message: 'Podaj prawidłowy adres e-mail', trigger: 'blur' }

export function ruleRequired (field) {
  return {
    required: true,
    message: `Pole ${field} jest wymagane`,
    trigger: 'blur'
  }
}

export function ruleMinMax (field, min = false, max = false) {
  if (min && max) {
    return {
      min,
      max,
      message: `Pole ${field} nie może być krótsze niż ${min} i dłuższe niż ${max} znaków`,
      trigger: 'blur'
    }
  }
  if (min) {
    return {
      min,
      message: `Pole ${field} nie może być krótsze niż ${min} znaków`,
      trigger: 'blur'
    }
  }
  return {
    max,
    message: `Pole ${field} nie może być dłuższe niż ${max} znaków`,
    trigger: 'blur'
  }
}

export function ruleLength (field, l) {
  return {
    len: l,
    message: `Pole ${field} musi mieć ${l} znaków`,
    trigger: 'blur'
  }
}

export function ruleNumber (field) {
  return {
    type: 'number',
    message: `Pole ${field} musi być liczbą.`,
    trigger: 'blur'
  }
}

export function ruleInteger (field) {
  return {
    type: 'number',
    message: `Pole ${field} musi być liczbą całkowitą.`,
    trigger: 'blur'
  }
}

export function ruleFloat (field) {
  return {
    type: 'float',
    message: `Pole ${field} musi być liczbą zmiennoprzecinkową.`,
    trigger: 'blur',
    transform: (value) => {
      if (value === null) {
        return null
      }
      value = value.trim()
      return toNumber(value.replace(',', '.'))
    }
  }
}

export function ruleMoney (field) {
  return {
    type: 'boolean',
    message: `Pole ${field} musi być poprawną kwotą.`,
    trigger: 'blur',
    transform: (value) => {
      if (value === null) {
        return null
      }
      value = value.trim()
      return toNumber(value.replace(',', '.'))
    }
  }
}

export function ruleUrl (field) {
  return {
    type: 'url',
    message: `Format ${field} jest nieprawidłowy.`,
    trigger: 'blur'
  }
}

export function validatorPasswordStrength (field, value, callback) {
  let result = zxcvbn(value)
  if (result.score < 3) {
    callback(new Error('Proszę podaj silne hasło'))
  }
  callback()
}

export function ruleAjax (method, message) {
  const fn = (rule, value, callback) => {
    getRequest('/api/validation/' + method, { keyword: value })
      .then(response => {
        if (!response.valid) {
          callback(new Error(message))
        }
        callback()
      })
      .catch(error => {
        callback()
      })
  }
  return { validator: fn, trigger: 'blur' }
}

export function ruleAjaxUnique (url, id = 0, message = 'Podana wartość nie jest unikalna.') {
  const fn = (rule, value, callback) => {
    getRequest(url, { id: id, keyword: value })
      .then(response => {
        if (!response.valid) {
          callback(new Error(message))
        }
        callback()
      })
      .catch(error => {
        callback()
      })
  }
  return { validator: fn, trigger: 'blur' }
}

export function ruleIban () {
  return ruleAjax('iban', 'Numer konta bankowego jest niepoprawny.')
}

// export function ruleIbanTax () {
//   return ruleAjax('iban_tax', 'Numer konta bankowego jest niepoprawny.')
// }

// export default {
//   ruleEmail,
//   ruleRequired,
//   validatorPasswordStrength,
//   ruleMinMax,
//   ruleLength,
//   ruleNumber,
//   ruleInteger,
//   ruleFloat,
//   ruleMoney,
//   ruleUrl,
//   ruleAjax,
//   ruleAjaxUnique
// }
