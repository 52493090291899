<template>
  <div>
    <h1>Login</h1>

    <el-form
      v-loading="loading"
      ref="loginForm"
      :model="form"
      label-position="top"
      @submit.native.prevent="handleForm('loginForm')"
    >

      <form-alerts :messages="invalidMsg"/>

      <el-form-item label="E-mail">
        <el-input
          v-validate="'required|email'"
          v-model="form.email"
          name="email"
          type="text"/>
        <errors
          :errors="formErrors"
          field="email"/>
      </el-form-item>

      <el-form-item label="Hasło">
        <PasswordField
          v-validate="'required'"
          id="password"
          v-model="form.password"
          name="password"/>
        <errors
          :errors="formErrors"
          field="password"/>
      </el-form-item>

      <el-form-item>
        <el-button
          class="w-100"
          native-type="submit"
          type="primary">Zaloguj się
        </el-button>
      </el-form-item>
    </el-form>

    <hr>

    <router-link
      :to="{name: 'auth.reset'}"
      style="width: 100%"
      class="el-button w-100">Zapomniałeś hasła?
    </router-link>
  </div>
</template>

<script>
    import forms from '@/shared/mixins/forms'
    import {postRequest} from '@/shared/utils/http'
    import PasswordField from '@/shared/components/PasswordField'

    export default {

    components: {
      PasswordField
    },

    mixins: [
      forms
    ],

    data: () => {
      return {
        form: {
          email: '',
          password: ''
        }
      }
    },

    methods: {
      submitForm: function () {
        return new Promise((resolve, reject) => {
          postRequest('/login', this.form)
            .then(response => {
              if (response.auth === 'true') {
                window.location = response.intended
              }
              resolve(response)
            })
            .catch(error => {
              reject(error)
            })
        })
      }
    }
  }
</script>
