<template>
  <div
    v-show="errors.has(field)"
    class="el-form-item__error">
    {{ errors.first(field) }}
  </div>
</template>

<script>
  export default {
    name: 'ValidationErrors',

    props: {
      errors: {
        type: Object,
        default: undefined
      },
      field: {
        type: String,
        default: ''
      }
    }
  }
</script>
