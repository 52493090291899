import axios from 'axios'

const DEFAULT_HEADERS = {
  'X-Requested-With': 'XMLHttpRequest',
  'X-CSRF-TOKEN': window.csrfToken,
  'Accept': 'application/json'
}

const validStatuses = [
  200, 201, 202, 203, 204,
  300, 301, 302, 303, 304
]

function getHeaders (multipart = false) {
  let defaultHeaders = DEFAULT_HEADERS

  if (multipart) {
    defaultHeaders = {}
  }

  return defaultHeaders
}

const checkResponse = function (response) {
  if (validStatuses.includes(response.status)) {
    if ('error' in response) {
      vm.$message.error(response.data.message)
    } else if (response.data.error === true) {
      vm.$message.error(response.data.message)
    } else if (response.data.message) {
      vm.$message({
        message: response.data.message,
        type: 'success'
      })
    }
    // return Promise.resolve(response.data)
  }

  // If not authorized
  // and redirect to login page ?
  if (response.status === 401) {
    return Promise.reject(new Error('USER_ANONYMOUS'))
  }

  // let err = new Error(response.statusText)
  // err.response = response
  //
  // return Promise.reject(err.response)
}

export function checkError (error) {
  // todo SPRAWDZIĆ CO PRZYCHODZI Z ZEWNĄTRZ
  if (typeof error !== 'object' || !('response' in error)) {
    return
  }
  if (error.response) {
    if ('showInMessages' in error.response.data && error.response.data.showInMessages === false) {
      return
    }

    if (error.response.status === 403) {
      console.error('403: ' + error.request.responseURL)
      vm.$message.error('Nie masz dostępu do tego zasobu!')
    }

    if (error.response.status !== 422) {
      vm.$message.error(error.response.data.message)
    }
  } else if (error.request) {
    console.log('Request error:')
    console.log(error.request)
  } else {
    console.log('Error', error.message)
  }
}

export const esc = encodeURIComponent

export function qs (params) {
  return (
    Object
      .keys(params)
      .map(k => esc(k) + '=' + esc(params[k]))
      .join('&')
  )
}

const sendRequest = function (method, uri, data, config) {
  return new Promise((resolve, reject) => {
    axios[method](uri, JSON.parse(JSON.stringify(data)), config)
      .then(response => {
        checkResponse(response)
        resolve(response.data)
      })
      .catch(errors => {
        checkError(errors)
        reject(errors.response)
      })
  })
}

// @todo te metody xxx wykasować, zostaną tylko xxxRequest
export function post (uri, data) {
  return sendRequest('post', uri, data, {
    headers: getHeaders()
  })
}

export function put (uri, data = {}) {
  return sendRequest('put', uri, data, {
    headers: getHeaders()
  })
}

export function remove (uri, data = {}) {
  return sendRequest('delete', uri, data, {
    headers: getHeaders()
  })
}

export function get (uri, data = {}) {
  if (Object.keys(data).length > 0) {
    uri = `${uri}?${qs(data)}`
  }

  return sendRequest('get', uri, data, {
    headers: getHeaders()
  })
}

// @todo pozostaną tylko xxxRequest
export function postRequest (uri, data) {
  return sendRequest('post', uri, data, {
    headers: getHeaders()
  })
}

export function putRequest (uri, data = {}) {
  return sendRequest('put', uri, data, {
    headers: getHeaders()
  })
}

export function patchRequest (uri, data = {}) {
  return sendRequest('patch', uri, data, {
    headers: getHeaders()
  })
}

export function deleteRequest (uri, data = {}) {
  return sendRequest('delete', uri, data, {
    headers: getHeaders()
  })
}

export function getRequest (uri, data = {}) {
  if (Object.keys(data).length > 0) {
    uri = `${uri}?${qs(data)}`
  }

  return sendRequest('get', uri, data, {
    headers: getHeaders()
  })
}

export function downloadRequest (uri, filename, data = {}) {
  if (Object.keys(data).length > 0) {
    uri = `${uri}?${qs(data)}`
  }

  return new Promise((resolve, reject) => {
    axios.get(uri, {
      responseType: 'blob',
      headers: getHeaders()
    }).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', filename)
      document.body.appendChild(link)
      link.click()
    })
      .catch(errors => {
        checkError(errors)

        reject(errors.response)
      })
  })
}

/*
axios({
url: 'http://localhost:5000/static/example.pdf',
method: 'GET',
responseType: 'blob', // important
}).then((response) => {
const url = window.URL.createObjectURL(new Blob([response.data]));
const link = document.createElement('a');
link.href = url;
link.setAttribute('download', 'file.pdf');
document.body.appendChild(link);
link.click();
});
* */

export function uploadRequest (uri, data) {
  return fetch(uri, {
    headers: getHeaders(true),
    cors: true,
    method: 'POST',
    body: data
  })
}

// Means endpoint
export function e (uri) {
  return BASE_URL + uri
}

export { checkResponse }

// *************** OLD *   *************

// @deprecated
export function createItem (endpoint, data) {
  return axios.post(endpoint, data)
    .then(response => {
      return response
    })
}

// @deprecated
export function getItem (endpoint, id = null, params = {}) {
  const url = (id === null) ? endpoint : endpoint + '/' + id
  return axios.get(url, { params })
    .then(response => {
      return response
    })
}

// @deprecated
export function updateItem (endpoint, id, data) {
  const url = (id === null) ? endpoint : endpoint + '/' + id
  return axios.put(url, data)
    .then(response => {
      return response
    })
}

// @deprecated
export function deleteItem (endpoint, id) {
  return axios.delete(endpoint + '/' + id)
    .then(response => {
      return response
    })
}

// @deprecated
export function sendPost (url, data) {
  return axios.post(url, data)
    .then(response => {
      return response
    })
}

// @deprecated
export function sendPut (url, data) {
  return axios.put(url, data)
    .then(response => {
      return response
    })
}

// @deprecated
export function sendGet (url) {
  return axios.get(url)
    .then(response => {
      return response
    })
}
