<template>
    <div>
        <el-alert
                v-for="(msg, index) in messages"
                v-if="messages.length > 0"
                :title="msg"
                :key="index"
                type="error"
                :class="{'mb-2': lastIndex > index, 'mb-5': lastIndex == index}"
                show-icon>
        </el-alert>
    </div>
</template>

<script>
  export default {
    name: 'FormAlerts',

    computed: {
      lastIndex: function () {
        return this.messages.length - 1
      }
    },

    props: {
      messages: Array
    }
  }
</script>
