<template>
  <div>
    <el-input
      ref="pass-item"
      :type="passwordType"
      v-bind="$attrs"
      class="password-field"
      @input="val => updateValue(val)"
      @keyup.native="check">
      <i
        slot="suffix"
        :class="['fas', icon]"
        title="Pokaż/ukryj hasło"
        @click="togglePassword"></i>
    </el-input>

    <template v-if="withSuggestions">
      <div class="password-lines">
        <div :class="passwordLineBg[1]"></div>
        <div :class="passwordLineBg[2]"></div>
        <div :class="passwordLineBg[3]"></div>
        <div :class="passwordLineBg[4]"></div>
      </div>
      <span class="help is-danger">{{ warning }}</span>
      <span
        v-for="suggestion in suggestions"
        :key="suggestion"
        class="help is-info">{{ suggestion }}</span>
    </template>
  </div>
</template>

<script>
  export default {
    name: 'PasswordField',

    inheritAttrs: false,

    $_veeValidate: {
      value () {
        return this.innerValue
      }
    },

    props: {
      withSuggestions: {
        type: Boolean,
        default: false
      },
      id: String,
      name: String
    },

    data () {
      return {
        password: '',
        passwordType: 'password',
        score: 0,
        suggestions: [],
        warning: '',
        passwordLineBg: [],
        icon: 'fa-eye',
        innerValue: '' // for vee-validate
      }
    },

    computed: {
      strength: function () {
        return this.score * 25
      }
    },

    methods: {
      updateValue: function (value) {
        this.$emit('input', value)
        this.innerValue = value
      },

      togglePassword: function (a) {
        this.passwordType = this.passwordType === 'password' ? 'text' : 'password'
        this.$refs['pass-item'].$el.setAttribute('type', this.passwordType)
        this.$refs['pass-item'].focus()
        this.icon = this.passwordType === 'password' ? 'fa-eye' : 'fa-eye-slash'
      },

      check: function (e) {
        if (e.target.type !== 'password') {
          return false
        }
        if (!this.withSuggestions) {
          return false
        }
        let result = zxcvbn(e.target.value)
        this.score = result.score
        this.warning = result.feedback.warning
        this.suggestions = result.feedback.suggestions
        this.passwordLineBg = this.calculatePassBg(e.target)
      },

      calculatePassBg: function (field) {
        if (field.value.length > 0) {
          if (this.score === 1) {
            return [null, 'bg-danger', '', '', '']
          } else if (this.score === 2) {
            return [null, 'bg-success', 'bg-success', 'bg-success', 'bg-success']
          } else if (this.score === 3) {
            return [null, 'bg-success', 'bg-success', 'bg-success', 'bg-success']
          } else if (this.score === 4) {
            return [null, 'bg-success', 'bg-success', 'bg-success', 'bg-success']
          }
        }
        return [null, '', '', '', '']
      }
    }
  }
</script>
