import './plugins'
import router from './router'
import App from './Auth.vue'
import Vue from 'vue'

window.Vue = Vue

Vue.config.debug = true

let vm = new Vue({
  el: '#app',
  router,
  components: { App },
  template: '<App/>'
})

window.vm = vm
